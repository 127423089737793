module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: "Beverley Hood", // Navigation and Site Title
  siteTitleAlt: "Beverley - Portfolio", // Alternative Site title for SEO
  siteHeadline: "Portfolio website for Beverley Hood", // Headline for schema.org JSONLD
  siteTitleShort: "Emma", // short_name for manifest
  siteUrl: "https://www.bhood.co.uk/", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/logo.png", // Used for SEO and manifest
  siteDescription: "Portfolio website for Beverley Hood",
  author: "Bev Hood", // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@beverley_hood", // Twitter Username
  ogSiteName: "emma", // Facebook Site Name
  ogLanguage: "en_US",

  // Manifest and Progress color
  themeColor: "#3498DB",
  backgroundColor: "#2b2e3c"
};
