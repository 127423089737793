import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 2rem;
  max-width: ${props => props.theme.container[props.type]};
  ${props =>
    props.type === "text" &&
    css`
      .gatsby-resp-image-background-image {
        padding-bottom: 0 !important;
        width: 100% !important;
        margin: 0 auto;
      }
      img {
        width: 100%;
      }
      img[src*="#gif"] {
        max-width: 375px;
        margin: 0 auto;
        display: block;
      }
      p {
        font-weight: 300;
        font-size: 14px;
        letter-spacing: -0.003em;
        line-height: 1.58;
        --baseline-multiplier: 0.179;
        --x-height-multiplier: 0.35;
        hyphens: none;
      }
      strong {
        font-weight: 400;
      }
      a {
        text-decoration: underline;
      }
    `};
  @media (max-width: ${props => props.theme.breakpoints.s}),
    (max-device-width: ${props => props.theme.breakpoints.s}) {
    padding: 1rem;
  }
`;

const Container = ({ children, type, className }) => (
  <Wrapper className={className} type={type}>
    {children}
  </Wrapper>
);

export default Container;

Container.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["base", "text"]),
  className: PropTypes.string
};

Container.defaultProps = {
  type: "base",
  className: null
};
