import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { FaInstagram, FaTwitter, FaEnvelope } from "react-icons/fa";
import styled from "styled-components";
import config from "../../config/website";

const Wrapper = styled.header`
  padding: 1em;
  background-color: white;
  align-items: center;
  display: flex;
  position: relative;
  z-index: 1000;
  a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &:hover {
      color: ${props => props.theme.brand.primary};
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    color: rgb(193, 193, 193);
    text-transform: uppercase;
    font-size: 18px;
    @media only screen and (max-width: 550px) {
      font-size: 14px !important;
    }
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
`;

const Name = styled.div`
  font-weight: 400;
  text-transform: uppercase;
  font-size: 40px !important;
  margin-bottom: 0 !important;
  @media only screen and (max-width: 550px) {
    font-size: 18px !important;
  }
`;

const Links = styled.div``;

const SocialMedia = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: flex-end;
  height: 100%;
  a {
    font-size: 18px;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
`;

// Grabs all MDX files from src/pages and puts them into the navigation

const Navigation = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Wrapper data-testid="navigation">
        <Nav>
          <Link to="/" data-testid="home-title-link">
            <Name>{config.siteTitle}</Name>
          </Link>
          <Links>
            <Link to="/">Projects</Link>
            <Link to="/about" data-testid="home-title-link">
              about
            </Link>
            {data.nav.edges.map((nav, index) => (
              <Link
                key={nav.node.fields.slug}
                to={nav.node.fields.slug}
                data-testid={`navItem-${index}`}
                activeClassName="nav-active"
              >
                {nav.node.frontmatter.title}
              </Link>
            ))}
          </Links>
        </Nav>

        <SocialMedia>
          <a
            href="mailto:info@bhood.co.uk?Subject=hello!"
            target="_top"
            rel="noopener noreferrer"
            aria-label="Behance"
          >
            <FaEnvelope />
          </a>
          <a
            href="https://www.instagram.com/beverley.hood.studio/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </a>

          <a
            href="https://twitter.com/beverley_hood"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Dribbble"
          >
            <FaTwitter />
          </a>
        </SocialMedia>
      </Wrapper>
    )}
  />
);

export default Navigation;

const query = graphql`
  query NavLinks {
    nav: allMdx(filter: { fields: { sourceInstanceName: { eq: "pages" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
