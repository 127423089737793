import { darken } from "polished";

const brand = {
  primary: "#666666",
  secondary: "#2b2e3c"
};

const colors = {
  grey: "#6b6b6b",
  black: "#000",
  white: "#fff",
  bg_color: "#f3f3f3",
  body_color: "#666666",
  link_color: "#666666",
  link_color_hover: `${darken(0.15, "#666666")}`
};

const theme = {
  brand,
  colors,
  breakpoints: {
    xs: "400px",
    s: "600px",
    m: "900px",
    l: "1200px"
  },
  container: {
    base: "100rem",
    text: "960px"
  },
  spacer: {
    horizontal: "2rem",
    vertical: "3rem"
  }
};

export default theme;
